<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Zone Information')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Change Zone Information')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <!--begin::Form Group-->
        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.zone.name_ar.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Name(Arabic)')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="zone.name_ar"
              :class="{ 'form-input--error': $v.zone.name_ar.$error }"
              :state="$v.zone.name_ar.$error === true ? false : null"
            />
          </div>
        </div>
        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.zone.name_en.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Name(English)')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="zone.name_en"
              :class="{ 'form-input--error': $v.zone.name_en.$error }"
              :state="$v.zone.name_en.$error === true ? false : null"
            />
          </div>
        </div>

        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.zone.price.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Price(JOD)')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="zone.price"
              :class="{ 'form-input--error': $v.zone.price.$error }"
              :state="$v.zone.price.$error === true ? false : null"
            />
          </div>
        </div>

        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.zone.displayed_price.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label"
            >{{$t('Displayed Price(JOD)')}}</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="zone.displayed_price"
              :class="{ 'form-input--error': $v.zone.displayed_price.$error }"
              :state="$v.zone.displayed_price.$error === true ? false : null"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Tookan Region ID</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="zone.tookan_region_id"
            />
          </div>
        </div>
        <!--begin::Form Group-->
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "ZoneEdit",

  props: {
    zone: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ clients: "clients/table" }),
  },
  validations: {
    zone: {
      name_ar: {
        required,
      },
      name_en: {
        required,
      },
      price: {
        required,
      },
      displayed_price: {
        required,
      },
    },
  },

  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store.dispatch("zones/update", this.zone);

          Swal.fire({
            title: "",
            text: "The queue has been updated successfully!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
    cancel() {},
  },
};
</script>


<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>